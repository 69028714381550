<template>
    <div>
        <a-card class="lf-card" size="small">

            <div slot="title">
                <span>生活照</span>
                <small class="text-minor ml-2">实体印刷报告将显示下面6张照片</small>
            </div>

            <a v-if="!readOnly" slot="extra" @click="activePicker">选择生活照</a>

            <div class="lf-list">
                <template v-for="(img,ik) in lifePhotos">

                    <div class="lf-img" :key="ik" :style="`background-image:url(${img})`"
                         @click="cropImage(ik,img)">

                        <div v-if="!readOnly" class="cropper-tips">剪裁照片</div>

                    </div>

                </template>

                <template v-if="lifePhotos.length < 6 && !readOnly">
                    <div class="lf-uploader" @click="acitveUploaderModal">
                        <a-icon type="upload"/>
                    </div>
                </template>

                <div class="lf-img empty"></div>
                <div class="lf-img empty"></div>
            </div>

            <div class="text-minor">
                <small v-if="!readOnly">如照片显示不完整，请点击进行裁剪，以免影响印刷报告效果</small>
            </div>
        </a-card>

        <a-modal v-model="showPickerModal" title="选择生活照" @ok="saveLifePhotos">

            <div class="lf-list">

                <template v-for="(img,ik) in listPhotos">

                    <div class="lf-img" :key="ik" :style="`background-image:url(${img})`" @click="selectImg(img)">
                        <span v-if="pickerList.indexOf(img) >= 0 " class="no">{{ pickerList.indexOf(img) + 1 }}</span>
                        <a @click.stop class="lf-link" :href="img" target="_blank">查看原图</a>
                    </div>

                </template>

                <div class="lf-img empty"></div>
                <div class="lf-img empty"></div>
                <div class="lf-img empty"></div>
            </div>

        </a-modal>

        <a-modal width="875px" title="生活照剪裁" :maskClosable="false" v-model="showCropperModal" @cancel="cancelImage"
                 @ok="cropedImage">
            <div class="cropper-img-container">
                <div class="cropper-img-left" v-if="cropperImageSrc">
                    <img @load="initCropper" id="cropperImage" :src="cropperImageSrc" alt="">
                </div>
                <div>
                    <div id="cropperImagePreview"></div>
                    <div class="text-center"><small>（预览图）</small></div>
                </div>
            </div>
            <a-button-group class="cropper-btns">
                <a-button @click="fd" type="primary">放大</a-button>
                <a-button @click="sx" type="primary">缩小</a-button>
                <a-button @click="spfz" type="primary">水平翻转</a-button>
                <a-button @click="czfz" type="primary">垂直翻转</a-button>
                <a-button @click="rotateLeft" type="primary">向左旋转</a-button>
                <a-button @click="rotateRight" type="primary">向右旋转</a-button>

            </a-button-group>
        </a-modal>

        <a-modal width="300px" v-model="showUploaderModal" :footer="null">
            <div class="uploader-text">
                <img width="200" height="200" :src="qrcodeUrl" alt="">
                <p>管理员扫码协助用户上传生活照</p>
            </div>
        </a-modal>
    </div>
</template>

<script>
import Cropper from "cropperjs";

export default {
    name: "LifePhoto",
    components: {},
    props: {
        detail: Object,
        readOnly: Boolean
    },
    mounted() {
        this.loadInfo()
    },
    watch: {
        'detail.extra.life_photos'() {
            this.loadInfo()
        }
    },
    data() {
        return {
            showUploaderModal: false,
            showPickerModal: false,
            lifePhotos: [],
            listPhotos: [],
            pickerList: [],
            showCropperModal: false,
            cropperImageSrc: null,
            cer: null,
            base64Image: null,
            cropperDetail: {},
            spfzd: 1,
            czfzd: 1,
            cropperImageIdx: 0,
        }
    },
    computed: {
        qrcodeUrl() {
            return this.$qrcode(`/packagesB/lifePhotos/lifePhotos?id=${this.detail.am_id}`, `${this.detail.am_id}-up-life-photo.png`)
        }
    },
    methods: {
        acitveUploaderModal(){
            this.showUploaderModal = true
        },
        activePicker() {
            this.showPickerModal = true
            this.pickerList = []
        },
        selectImg(img) {

            let idx = this.pickerList.indexOf(img)

            if (idx < 0) {
                if (this.pickerList.length >= 6) return;

                return this.pickerList.push(img)
            }

            this.pickerList.splice(idx, 1)
        },
        initCropper() {

            let el = document.getElementById('cropperImage');
            let preview = document.getElementById('cropperImagePreview')


            this.cer = new Cropper(el, {
                aspectRatio: 114 / 149,
                preview,
                dragMode: "move",
                crop: (event) => {
                    this.cropperDetail = event.detail
                },
            })
        },
        cropImage(ik, img) {

            if (this.readOnly) return window.open(img);

            this.cropperImageSrc = img
            this.showCropperModal = true
            this.cropperImageIdx = ik
        },
        cancelImage() {
            if (!this.cer) return;

            setTimeout(() => {
                this.cer.destroy()
                this.cer = null
            }, 200)
        },
        rotateLeft() {
            if (!this.cer) return;

            this.cer.rotate(-90)
        },
        rotateRight() {
            if (!this.cer) return;

            this.cer.rotate(90)
        },
        cropedImage() {
            if (!this.cer) return;

            let dta = this.cer.getCroppedCanvas().toDataURL();

            let photos = JSON.parse(JSON.stringify(this.lifePhotos))

            photos.splice(this.cropperImageIdx, 1, dta)

            this.pickerList = photos;

            this.showCropperModal = false

            this.saveLifePhotos()

            setTimeout(() => {

                this.cer.destroy()

            }, 50)
        },
        fd() {
            if (!this.cer) return;

            this.cer.scale(
                this.cropperDetail.scaleX * 1.1,
                this.cropperDetail.scaleY * 1.1,
            )
        },
        sx() {
            if (!this.cer) return;

            this.cer.scale(
                this.cropperDetail.scaleX * 0.9,
                this.cropperDetail.scaleY * 0.9,
            )
        },
        spfz() {
            if (!this.cer) return;

            this.spfzd = this.spfzd === -1 ? 1 : -1;

            this.cer.scale(this.spfzd, this.czfzd)
        },
        czfz() {
            if (!this.cer) return;

            this.czfzd = this.czfzd === -1 ? 1 : -1;

            this.cer.scale(this.spfzd, this.czfzd)
        },
        loadInfo() {

            let list = this.detail.member_image.life_photos

            if (!list) list = [];

            this.listPhotos = list;

            this.lifePhotos = this.detail.extra.life_photos

            if (!this.lifePhotos) {

                this.lifePhotos = list.slice(0, 6)
            }

        },
        saveLifePhotos() {
            this.showPickerModal = false

            this.$emit('change', [
                'life_photos', this.pickerList
            ])
        }
    }
}
</script>

<style scoped lang="less">
.lf-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
}

.lf-img {
    width: 114px;
    height: 149px;

    img {
        width: 1px;
        height: 1px;
        opacity: 0;
    }

    margin-bottom: 15px;

    background-color: black;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;

    margin-right: 15px;
    display: block;
    position: relative;
    cursor: pointer;

    .no {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 20px;
        height: 20px;
        background-color: black;
    }

    &.empty {
        height: 0;
    }

    .lf-link {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #dddddd;
        padding: 2px 5px;
        opacity: 0;
    }

    &:hover .lf-link {
        opacity: 1;
    }
}

.cropper-img-container {
    display: flex;
    flex-direction: row;
}

.cropper-img-left {
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#cropperImage {
    max-width: 600px;
    max-height: 500px;
}

#cropperImagePreview {
    width: 228px;
    height: 288px;
    overflow: hidden;
    background: white;
    border: 2px solid #dddddd;
}

.cropper-btns {
    /deep/ button {
        background-color: #333333;
        border-color: #dddddd !important;
    }
}

.lf-img:hover .cropper-tips {
    opacity: 1;
}

.cropper-tips {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(221, 221, 221, 0.42);
    color: white;
    opacity: 0;
}

.lf-uploader {
    width: 114px;
    height: 149px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    border: 1px solid #dddddd;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.uploader-text {
    font-size: small;
    text-align: center;

    img {
        margin-bottom: 10px;
    }
}

</style>