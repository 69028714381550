<template>
    <div class="di-container">


        <template v-for="(group,gk) in groups">
            <a-card class="di-card" :key="gk" size="small">
                <div slot="title">
                    {{ group.name }}
                    <small>仅确认需要改动的某项属性</small>
                </div>
                <div class="di-item header">
                    <div class="xm">
                        <span><b>属性</b></span>
                    </div>
                    <div class="ai">
                        <span><b>AI判断</b></span>
                    </div>
                    <div class="cs">
                        <span><b>人工确认</b></span>
                    </div>
                </div>

                <template v-for="(item,key) in group.items">

                    <div :key="key" class="di-item">
                        <div class="xm">
                            <span>{{ item.name }}</span>
                        </div>
                        <div class="ai">
                            <span>{{ aiText(item) }}</span>
                        </div>
                        <div :class="{readonly:readOnly}" class="cs" @click="activePickerModal(item,item.key)">
                            <span>{{ csText(item) }}</span>
                        </div>

                    </div>

                </template>

            </a-card>
        </template>

        <div class="di-card di-card-empty"></div>
        <div class="di-card di-card-empty"></div>
        <div class="di-card di-card-empty"></div>
        <div class="di-card di-card-empty"></div>

        <a-modal v-model="showPickerModal" :title="`调整【${pickerItem.name}】`" @ok="saveChange">
            <div v-if="pickerItem.options">
                <a-radio-group v-model="pickerValue">
                    <template v-for="(name,key) in pickerItem.options">
                        <div :key="key" class="radio-item">
                            <a-radio :value="key">{{ name }}</a-radio>
                        </div>
                    </template>
                </a-radio-group>
            </div>
        </a-modal>


    </div>
</template>

<script>
export default {
    name: "DetectInfo",
    props: {
        detectOptions: Object,
        readOnly: Boolean
    },
    watch: {
        detectOptions() {
            this.groupOptions()
        }
    },
    mounted() {
        this.groupOptions()
    },
    data() {
        return {
            pickerItem: {},
            showPickerModal: false,
            pickerValue: '',
            pickerType: '',
            groups: [
                {
                    name: "五官脸型",
                    keys: ['face', 'jaw', 'eyes', 'eyebrow', 'nose', 'mouth'],
                    items: [],
                },
                {
                    name: "风格属性",
                    keys: ['style_id', 'skin_type', 'mb_lg', 'mb_xt', 'mb_lk', 'fz_lk'],
                    items: [],
                },
                {
                    name: "色彩属性",
                    keys: ['color', 'mb_ln', 'mb_cd', 'mb_md'],
                    items: [],
                }
            ],
        }
    },
    methods: {
        groupOptions() {

            for (const group of this.groups) {

                for (const key of group.keys) {

                    let item = this.detectOptions[key];

                    if (!item) continue;

                    item['key'] = key

                    group.items.push(item)
                }


            }
        },
        activePickerModal(item, type) {
            if (this.readOnly) return;

            this.pickerType = type
            this.pickerItem = item
            this.pickerValue = item.cs || item.ai
            this.showPickerModal = true
        },
        aiText(item) {
            let ai = item.ai

            if (!item.ai) return ' - ';

            return item.options[ai] || '-'
        },
        csText(item) {

            let cs = item.cs

            if (!item.cs) return ' - ';

            return item.options[cs] || '-'
        },
        saveChange() {
            this.showPickerModal = false

            this.$emit('change', [
                this.pickerType,
                this.pickerValue
            ])
        },
    }
}
</script>

<style scoped lang="less">
.di-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

}

.di-card {
    width: 380px;
    margin-right: 32px;
    margin-bottom: 32px;
}

.di-item {


    &.header {
        .cs, .ai, .xm {
            span {
                cursor: auto;
            }

            b {

            }
        }
    }

    margin-bottom: 16px;
    display: flex;

    > div {
        width: 33.33%;
    }

    .cs {
        cursor: pointer;

        span {
            background-color: lightseagreen;
        }

        &.readonly {
            span {
                background-color: #dddddd;
                cursor: auto;
            }
        }
    }

    .xm {
        span {
            background-color: skyblue;
        }
    }

    span {
        display: block;
        width: 90%;
        background-color: #1890ff;
        color: white;
        padding: 4px;
        text-align: center;
        border-radius: 5px;
    }
}

.radio-item {
    margin-bottom: 5px;
}

.di-card-empty{
    margin-bottom: 0;
}
</style>