<template>
    <div>
        <a-card class="lf-card" size="small">

            <div slot="title">
                <span>更多搭配展示</span>
                <small class="text-minor ml-2">实体印刷报告将显示下面搭配照片</small>
            </div>

            <div class="lf-list">
                <template v-for="(img,ik) in extraImages">

                    <div class="lf-img" :key="ik" :style="`background-image:url(${img})`"
                        @click="openImage(img)">
                        <a @click.stop="delLfImage(ik)" class="lf-img-del">删除</a>
                    </div>

                </template>

                <template v-if="extraImages.length < 36 && !readOnly">
                    <upload-image @change=" file => updateExtraPhotos(file)  "/>
                </template>

                <div class="lf-img empty"></div>
                <div class="lf-img empty"></div>
            </div>
        </a-card>

        <a-modal v-model="showPickerModal" title="选择生活照" @ok="saveExtraPhotos">

            <div class="lf-list">

                <template v-for="(img,ik) in listPhotos">

                    <div class="lf-img" :key="ik" :style="`background-image:url(${img})`" @click="selectImg(img)">
                        <span v-if="pickerList.indexOf(img) >= 0 " class="no">{{ pickerList.indexOf(img) + 1 }}</span>
                    </div>

                </template>

                <div class="lf-img empty"></div>
                <div class="lf-img empty"></div>
                <div class="lf-img empty"></div>
            </div>

        </a-modal>

    </div>
</template>

<script>
import UploadImage from "@/components/UploadImage.vue";

export default {
    name: "ExImage",
    components: {UploadImage},
    props: {
        detail: Object,
        readOnly: Boolean
    },
    mounted() {
        this.loadInfo()
    },
    watch: {
        'detail.extra.extra_images'() {
            this.loadInfo()
        }
    },
    data() {
        return {
            showPickerModal: false,
            extraImages: [],
            listPhotos: [],
            pickerList: [],
        }
    },
    methods: {
        activePicker() {
            this.showPickerModal = true
            this.pickerList = []
        },
        updateExtraPhotos(file) {
            this.extraImages.push(file)
            this.saveExtraPhotos()
        },
        selectImg(img) {

            let idx = this.pickerList.indexOf(img)

            if (idx < 0) {
                return this.pickerList.push(img)
            }

            this.pickerList.splice(idx, 1)
        },
        loadInfo() {
            if (!this.detail) return;

            this.extraImages = [];
            this.extraImages = this.detail.extra.extra_images || []
        },
        saveExtraPhotos() {
            this.$emit('change', [
                'extra_images', this.extraImages
            ])
        },
        openImage(url){
            window.open(url)
        },
        delLfImage(idx) {
            this.$confirm({
                title: '确定要删除这张图片吗?',
                onOk: () => {
                    this.extraImages.splice(idx, 1)
                    this.saveExtraPhotos()
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
.lf-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
}

.lf-img {
    width: 102px;
    height: 102px;
    margin-bottom: 15px;
    background-size: cover;
    background-position: center;
    margin-right: 15px;
    display: block;
    cursor: pointer;
    .no {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 20px;
        height: 20px;
        background-color: black;
    }

    &.empty {
        height: 0;
    }
}

.lf-img:hover .lf-img-del {
    opacity: 1;
}

.lf-img-del {
    padding: 2px 3px;
    background-color: #dddddd;
    opacity: 0;
}
</style>